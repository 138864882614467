import request from "./request";
// 获取图片验证码
export const ImgCodeApi = () => request.get("/admin/login/get_img_code");
// 登录
export const LoginApi = (params) => request.post("/admin/login/login", params);
// 自动登录
export const AutoLoginApi = () => request.get("/admin/login/auto_login");
//公共模块  获取主机域名
export const GetHostApi = () => request.get("/admin/Common/get_host");
//公共模块  上传图片
export const UploadApi = (params) =>
  request.post("/admin/Common/upload_img", params);

// 电缆管理 添加电缆
export const CablesAddApi = (params) =>
  request.post("/admin/cables/add", params);
// 电缆管理 电缆选项
export const CablesOptionsApi = (params) =>
  request.post("/admin/cables/options", params);
// 电缆管理 更新电缆 /admin/cables/update
export const CablesUpdateApi = (params) =>
  request.post("/admin/cables/update", params);
// 电缆管理 电缆列表
export const CablesListApi = (params) =>
  request.post("/admin/cables/list", params);
// 电缆管理 电缆详情
export const CablesGetApi = (params) =>
  request.post("/admin/cables/get", params);
// 电缆管理 删除电缆
export const CablesDelApi = (params) =>
  request.post("/admin/cables/del", params);
// 场站电缆选项 /admin/cables/station_options
export const StationOptionsApi = (params) =>
  request.post("/admin/cables/station_options", params);

// 能源路由器管理  能源路由器选项  /admin/centralized_controller/options
export const ControlOptionsApi = (params) =>
  request.post("/admin/centralized_controller/options", params);
// 场站能源路由器选项
export const ControlStationOptionsApi = (params) =>
  request.post("/admin/centralized_controller/station_options", params);
// 能源路由器管理  添加能源路由器
export const ControlAddApi = (params) =>
  request.post("/admin/centralized_controller/add", params);
// 能源路由器管理  更新能源路由器
export const ControlUpdateApi = (params) =>
  request.post("/admin/centralized_controller/update", params);
// 能源路由器管理  能源路由器详情
export const ControlGetApi = (params) =>
  request.post("/admin/centralized_controller/get", params);
// 能源路由器管理  能源路由器列表
export const ControlListApi = (params) =>
  request.post("/admin/centralized_controller/list", params);
// 能源路由器管理  删除能源路由器
export const ControlDeleteApi = (params) =>
  request.post("/admin/centralized_controller/delete", params);
// 能源路由器管理 重启能源路由器
export const ControlRestartApi = (params) =>
  request.post("/admin/centralized_controller/restart", params);

// 驱动服务 列表数据
export const getServicesDataApi = (params) =>
  request.post("/admin/drive_services/get_list_data", params);
// 驱动服务 获取选项数据
export const getServicesOptionApi = (params) =>
  request.post("/admin/drive_services/get_options", params);
// 开启心跳间隔数据上报
export const HeartbeatSwitchApi = (params) =>
  request.post("/admin/drive_services/heartbeat_interval_switch", params);

// 运营商选项 /admin/corp/options
export const CorpOptioanApi = (params) =>
  request.post("/admin/corp/options", params);

// 业务平台的管理员分组选项
export const AdminGroupOptionsApi = (params) =>
  request.post("/admin/corp/admin_group_options", params);

// 验证管理员用户名是否已经被使用
export const AdminUserNameCheckApi = (params) =>
  request.post("/admin/corp/verify_admin_username", params);

// 能源路由器配置管理/查询配置
export const GetCentralizedControllerApi = (params) =>
  request.post("/admin/centralized_controller_config/get", params);
// 能源路由器配置管理/更新配置
export const UpdateCentralizedControllerApi = (params) =>
  request.post("/admin/centralized_controller_config/update", params);

// 业务平台 业务平台列表
export const PlatformListDataApi = (params) =>
  request.post("/admin/business_platform/get_list_data", params);
// 业务平台 添加业务平台
export const PlatformAddApi = (params) =>
  request.post("/admin/business_platform/add", params);
// 业务平台 详情数据
export const PlatformDetailApi = (params) =>
  request.post("/admin/business_platform/get_detail_data", params);
// 业务平台 更新数据
export const PlatformUpdateApi = (params) =>
  request.post("/admin/business_platform/update_data", params);
// 业务平台 获取应用程序
export const PlatformSecretApi = (params) =>
  request.post("/admin/business_platform/get_app_secret", params);
// 业务平台 业务平台选项
export const BusinessOptionsApi = (params) =>
  request.post("/admin/business_platform/options", params);

// 空开管理 空开选项
export const AirSwitchOptionsApi = (params) =>
  request.post("/admin/air_switch/options", params);
// 空开管理 添加空开
export const AirSwitchAddApi = (params) =>
  request.post("/admin/air_switch/add", params);
// 空开管理 更新空开
export const AirSwitchUpdateApi = (params) =>
  request.post("/admin/air_switch/update", params);
// 空开管理 空开列表
export const AirSwitchListApi = (params) =>
  request.post("/admin/air_switch/list", params);
// 空开管理 空开详情
export const AirSwitchGetApi = (params) =>
  request.post("/admin/air_switch/get", params);
// 空开管理 删除空开
export const AirSwitchDelApi = (params) =>
  request.post("/admin/air_switch/del", params);

// 监控中心 首页数据概览
export const DataOverviewApi = (params) =>
  request.post("/admin/monitoringCenter/dataOverview", params);
// 监控中心 每月统计列表
export const MonthlyStatisticsApi = (params) =>
  request.post("/admin/monitoringCenter/monthlyStatistics", params);
// 监控中心 每日统计列表
export const DailyStatisticsApi = (params) =>
  request.post("/admin/monitoringCenter/dailyStatistics", params);
// 监控中心  查询充电站的月排行榜前10名
export const RankingListApi = (params) =>
  request.post("/admin/monitoringCenter/stationsMonthRankingListTop10", params);

// 运营中心/场站监控 电量使用情况统计
export const ElectricityUsageApi = (params) =>
  request.post("/admin/stationsMonitor/electricityUsageStatistics", params);
// 运营中心/场站监控 充电枪状态统计
export const ShotsStatusApi = (params) =>
  request.post("/admin/stationsMonitor/shotsStatusStatistics", params);
// 运营中心/场站监控  统计数据
export const StatisticsDataApi = (params) =>
  request.post("/admin/stationsMonitor/statisticsData", params);

//权限列表 获取菜单列表
export const MenuApi = () => request.get("/admin/Menu/menu_list");
//权限列表 获取用户菜单列表  /admin/Menu/user_menu_list
export const MenuListApi = () => request.get("/admin/menu/user_menu_list");
//权限列表 获取用户路由列表  /admin/Menu/user_route_list
export const RouteListApi = () => request.get("/admin/Menu/user_route_list");
//权限列表 菜单详情
export const MenuGetInfoApi = (params) =>
  request.post("/admin/Menu/get_info", params);
//权限列表 新增菜单
export const MenuAddApi = (params) => request.post("/admin/Menu/add", params);
//权限列表 编辑菜单
export const MenuEditApi = (params) => request.post("/admin/Menu/edit", params);
//权限列表 删除菜单
export const MenuDeleteApi = (params) =>
  request.post("/admin/Menu/delete", params);
//权限列表 更新菜单可用状态 /admin/Menu/update_state
export const UpdateStateApi = (params) =>
  request.post("/admin/Menu/update_state", params);
//权限列表 更新公开状态 /admin/Menu/update_auth_open
export const UpdateAuthOpenApi = (params) =>
  request.post("/admin/Menu/update_auth_open", params);
//权限列表 更新菜单可用状态 /admin/Menu/update_menu_state
export const UpdateMenuStateApi = (params) =>
  request.post("/admin/Menu/update_menu_state", params);
//权限列表 更新菜单排序 /admin/Menu/update_menu_state
export const UpdateSortApi = (params) =>
  request.post("/admin/Menu/update_sort", params);
// 权限列表  获取父菜单列表
export const GetPidListApi = (params) =>
  request.get("/admin/Menu/get_pid_list", params);

// 管理组  获取管理组权限列表  /admin/AdminGroup/get_rule_list
export const GetRuleListApi = (params) =>
  request.post("/admin/AdminGroup/get_rule_list", params);
// 管理组  管理组列表  /admin/AdminGroup/admin_group_list
export const AdminGroupListApi = (params) =>
  request.post("/admin/AdminGroup/admin_group_list", params);
// 管理组  管理组详情  /admin/AdminGroup/admin_group_list
export const GroupGetInfoApi = (params) =>
  request.post("/admin/AdminGroup/get_info", params);
// 管理组  新增管理组  /admin/AdminGroup/add
export const GroupAddApi = (params) =>
  request.post("/admin/AdminGroup/add", params);
// 管理组  编辑管理组  /admin/AdminGroup/edit
export const GroupEditApi = (params) =>
  request.post("/admin/AdminGroup/edit", params);
// 管理组  删除管理员  /admin/AdminGroup/delete
export const GroupDeleteApi = (params) =>
  request.post("/admin/AdminGroup/delete", params);
// 管理组  设置管理组权限  /admin/AdminGroup/set_admin_group_rule
export const SetAdminGroupApi = (params) =>
  request.post("/admin/AdminGroup/set_admin_group_rule", params);
// 管理组  获取管理组排序信息
export const SortListinfoApi = () =>
  request.get("/admin/AdminGroup/sort_list_info");
// 管理组选项
export const GroupOptionApi = () =>
  request.get("/admin/AdminGroup/get_options");

// 管理员列表  /admin/Admin/admin_list
export const AdminListApi = (params) =>
  request.post("/admin/Admin/admin_list", params);
// 管理员详情  /admin/Admin/get_info
export const AdminGetInfoApi = (params) =>
  request.post("/admin/Admin/get_info", params);
// 新增管理员  /admin/Admin/add
export const AdminAddApi = (params) => request.post("/admin/Admin/add", params);
// 编辑管理员  /admin/Admin/edit
export const AdminEditApi = (params) =>
  request.post("/admin/Admin/edit", params);
// 重设管理员密码  /admin/Admin/reset_password
export const AdminResetPasswordApi = (params) =>
  request.post("/admin/Admin/reset_password", params);
// 删除管理员  /admin/Admin/delete
export const AdminDeleteApi = (params) =>
  request.post("/admin/Admin/delete", params);
//管理员列表 获取管理员排序信息
export const AdminSortListinfoApi = () =>
  request.get("/admin/Admin/sort_list_info");
// 管理员 当前登录的管理员信息
export const CurrentUserInfoApi = (params) =>
  request.post("/admin/Admin/current_user_info", params);
// 管理员 重设当前管理员密码
export const ResetCurrentUserApi = (params) =>
  request.post("/admin/Admin/reset_current_user_password", params);

// 资源中心/充电站列表 /admin/stations/stations_list
export const StationsListApi = (params) =>
  request.post("/admin/Stations/stations_list", params);
// 资源中心/新增充电站 /admin/stations/add
export const StationsAddApi = (params) =>
  request.post("/admin/Stations/add", params);
// 资源中心/编辑充电站 /admin/stations/edit
export const StationsEditApi = (params) =>
  request.post("/admin/Stations/edit", params);
// 资源中心/删除充电站
export const StationsDelApi = (params) =>
  request.post("/admin/Stations/delete", params);
// 获取充电站排序信息 /admin/Stations/sort_list_info
export const StationsSortListInfoApi = () =>
  request.get("/admin/Stations/sort_list_info");
// 资源中心/充电站详情  /admin/Stations/get_info
export const StationGetInfoApi = (params) =>
  request.post("/admin/Stations/get_info", params);
// 资源中心/获取充电站列表
export const StationGetStationsApi = (params) =>
  request.post("/admin/Stations/stations_list", params);
// 资源中心 获取充电站选项
export const GetStationsListApi = (params) =>
  request.post("/admin/Stations/get_stations_list", params);

// 导入场站数据
export const StationsImportDataApi = (params) =>
  request.post("/admin/stations/import_data", params);

// 资源中心/充电桩列表  /admin/piles/piles_list
export const PileListApi = (params) =>
  request.post("/admin/Piles/piles_list", params);
// 资源中心/新增充电桩
export const PileAddApi = (params) => request.post("/admin/piles/add", params);
// 资源中心/编辑充电桩
export const PileEditApi = (params) =>
  request.post("/admin/Piles/edit", params);
// 资源中心/删除充电桩
export const PileDelApi = (params) =>
  request.post("/admin/Piles/delete", params);
// 充电桩排序信息  /admin/Piles/sort_list_info
export const PileSortListApi = () => request.get("/admin/Piles/sort_list_info");
// 获取充电桩列表 /admin/Piles/get_piles_list
export const GetPileListApi = (params) =>
  request.post("/admin/Piles/get_piles_list", params);
// 充电桩详情
export const PileGetInfoApi = (params) =>
  request.post("/admin/Piles/get_info", params);

// 资源中心/充电枪列表   /admin/shots/shots_list
export const ShotsApi = (params) =>
  request.post("/admin/Shots/shots_list", params);
// 资源中心/新增充电枪
export const GunAddApi = (params) => request.post("/admin/Shots/add", params);
// 资源中心/编辑充电枪
export const GunEditApi = (params) => request.post("/admin/Shots/edit", params);
// 资源中心/删除充电枪
export const GunDelApi = (params) =>
  request.post("/admin/Shots/delete", params);
// 获取充电枪排序信息
export const GunSortListApi = () => request.get("/admin/Shots/sort_list_info");
// 导出充电枪列表Excel
export const ExportApi = (params) =>
  request.post("/admin/Shots/out_shots_list_excel", params);
// 充电枪详情

export const GetGunInfoApi = (params) =>
  request.post("2", params);
// 资源中心/费率列表
export const TariffgroupApi = (params) =>
  request.post("/admin/TariffGroup/tariff_group_list", params);
// 资源中心/费率组详情   /admin/TariffGroup/get_info
export const TariffgroupGetInfoApi = (params) =>
  request.post("/admin/TariffGroup/get_info", params);
// 资源中心/新增费率组
export const TariffgroupAddApi = (params) =>
  request.post("/admin/TariffGroup/add", params);
// 资源中心/编辑费率组
export const TariffgroupEditApi = (params) =>
  request.post("/admin/TariffGroup/edit", params);
// 资源中心/删除费率组
export const TariffgroupDelApi = (params) =>
  request.post("/admin/TariffGroup/delete", params);
// 资源中心/费率组列表
export const GetTariffGroupApi = (params) =>
  request.post("/admin/TariffGroup/get_tariff_group_list", params);
// 资源中心/费率组接口
export const GetPeriodRateListApi = () =>
  request.get("/admin/TariffGroup/get_period_rate_list");
// 资源中心/获取时段费率类型
export const GetPeriodRateTypeApi = () =>
  request.get("/admin/TariffGroup/get_period_rate_type");
// 获取费率组排序信息
export const TariffGroupSortListApi = () =>
  request.get("/admin/TariffGroup/sort_list_info");

// 会员中心/运营商列表  /admin/menu/corp_list
export const CorpListApi = (params) =>
  request.post("/admin/corp/corp_list", params);
// 会员中心/新增运营商 /admin/corp/add
export const CorpListAddApi = (params) =>
  request.post("/admin/corp/add", params);
// 会员中心/编辑运营商
export const CorpListEditApi = (params) =>
  request.post("/admin/corp/edit", params);
// 会员中心/删除运营商
export const CorpListDelApi = (params) =>
  request.post("/admin/corp/delete", params);
// 会员中心/更新审核状态  /admin/corp/update_audit_status
export const UpdateAuditStatusApi = (params) =>
  request.post("/admin/corp/update_audit_status", params);
// 会员中心/更新运营商状态
export const UpdateStatusApi = (params) =>
  request.post("/admin/corp/update_status", params);
// 会员中心/获取运营商列表  /admin/corp/get_corp_list
export const GetCorpListApi = (params) =>
  request.post("/admin/Corp/get_corp_list", params);
// 会员中心/运营商详情
export const GetInfoApi = (params) =>
  request.post("/admin/corp/get_info", params);
// 会员中心/获取运营商排序信息    /admin/Corp/sort_list_info
export const SortListInfoApi = () => request.get("/admin/Corp/sort_list_info");
// 获取城市   /admin/City/get_city
export const CitytApi = (params) =>
  request.post("/admin/City/get_city", params);

// 系统管理/管理员列表 /admin/user/user_list
export const UserListApi = (params) =>
  request.post("/admin/user/user_list", params);
// 系统管理/新增管理员  /admin/user/add
export const UserListAddApi = (params) =>
  request.post("/admin/user/add", params);
// 系统管理/编辑管理员  /admin/user/edit
export const UserListEditApi = (params) =>
  request.post("/admin/user/edit", params);
// 系统管理/删除管理员  /admin/user/delete
export const UserListDelApi = (params) =>
  request.post("/admin/user/delete", params);

// 运营中心/充电桩控制 / 获取充电桩排序信息
export const PilesControlSortListApi = () =>
  request.get("/admin/PilesControl/sort_list_info");
// 运营中心 充电桩控制列表  /admin/PilesControl/index
export const PilesControlApi = (params) =>
  request.post("/admin/PilesControl/index", params);
// 运营中心/下发费率
export const PilesControltariffApi = (params) =>
  request.post("/admin/PilesControl/issue_tariff_group", params);

// webSocket
export const LoginWebSocketApi = (params) =>
  request.post("/admin/Login/web_socket", params);

// 发票管理 开票记录  /admin/electronic_invoice/list
export const ElectronicInvoiceApi = (params) =>
  request.post("/admin/electronic_invoice/list", params);
// 发票管理 开票统计
export const InvoiceStatisticApi = (params) =>
  request.post("/admin/electronic_invoice/statistics", params);

// 订单管理  /admin/Order/get_order_list
export const GetOrderListApi = (params) =>
  request.post("/admin/Order/get_order_list", params);
//订单详情  /admin/Order/get_order_info
export const GetOrderInfoApi = (params) =>
  request.post("/admin/Order/get_order_info", params);
//订单修复 /admin/Order/order_repair
export const OrderRepairApi = (params) =>
  request.post("/admin/Order/order_repair", params);

// 告警记录列表
export const AlarmRecordApi = (params) =>
  request.post("/admin/AlarmRecord/get_list_data", params);
// 删除告警记录
export const AlarmRecordDeleteApi = (params) =>
  request.post("/admin/AlarmRecord/delete_data", params);

// 运维管理/主动告警 / 停止原因选项
export const ReasonForStopApi = (params) =>
  request.post("/admin/active_alarm/reason_for_stop_options", params);

// 运维管理/主动告警 / 获取主动告警配置 /admin/active_alarm/get_config
export const GetConfigApi = (params) =>
  request.post("/admin/active_alarm/get_config", params);

// 运维管理/主动告警 / 更新主动告警配置
export const UpdateConfigApi = (params) =>
  request.post("/admin/active_alarm/update_config", params);

// 运维管理/工单管理  工单列表
export const WorkOrderListApi = (params) =>
  request.post("/admin/work_order/list", params);

// 运维管理/工单管理  创建工单
export const WorkOrderCteateApi = (params) =>
  request.post("/admin/work_order/create", params);

// 运维管理/工单管理  工单详情
export const WorkOrderInfoApi = (params) =>
  request.post("/admin/work_order/info", params);

// 运维管理/工单管理  处理工单
export const WorkOrderProcessApi = (params) =>
  request.post("/admin/work_order/process", params);

// 软件管理/版本管理 版本列表
export const versionListApi = (params) =>
  request.post("/admin/piles_software_version/version_list", params);

// 软件管理/版本管理 版本选项
export const versionOpitionApi = (params) =>
  request.post("/admin/piles_software_version/version_options", params);

// 软件管理/版本管理 上传软件包
export const versionUploadApi = (params) =>
  request.post("/admin/piles_software_version/upload", params);

// 软件管理/版本管理 发布版本
export const PublishApi = (params) =>
  request.post("/admin/piles_software_version/publish_version", params);

// 软件管理/软件更新 任务列表
export const GetListDataApi = (params) =>
  request.post("/admin/piles_software_update_task/get_list_data", params);

// 软件管理/软件更新 任务指令列表
export const GetCommandListApi = (params) =>
  request.post("/admin/piles_software_update_task/get_command_list", params);

// 软件管理/软件更新 创建更新任务-4G
export const CreateTaskApi = (params) =>
  request.post("/admin/piles_software_update_task/create_task", params);
// 软件管理/软件更新 创建更新任务-PLC
export const CreateTaskPLCApi = (params) =>
  request.post("/admin/piles_software_update_task/create_plc_task", params);
// 充电桩硬件版本管理 列表数据
export const GetHardwareVersionApi = (params) =>
  request.post("/admin/piles_hardware_version/get_list_data", params);
// 充电桩硬件版本管理 添加
export const AddHardwareVersionApi = (params) =>
  request.post("/admin/piles_hardware_version/add", params);
// 充电桩硬件版本管理 删除
export const DelHardwareVersionApi = (params) =>
  request.post("/admin/piles_hardware_version/remove", params);
// 充电桩硬件版本管理 选项列表
export const HardwareVersionOptionsApi = (params) =>
  request.post("/admin/piles_hardware_version/get_options", params);
// 充电桩硬件版本管理 获取充电桩排序信息
export const HardwareSortApi = () =>
  request.get("/admin/piles_hardware_version/sort_list_info");

// 报表管理 查询PLC运维的导出数据
export const ExportPlcDataApi = (params) =>
  request.post("/admin/report_form/export_plc_dev_ops_data", params);
// Plc运维表
export const PlcListApi = (params) =>
  request.post("/admin/report_form/plc_dev_ops_list", params);

// 立即升级
export const PileUpdateApi = (params) =>
  request.post("/admin/piles_software_update_task/upgrade_now", params);
// 任务详情
export const PileUpdateInfoDataApi = (params) =>
  request.post("/admin/piles_software_update_task/get_info_data", params);
// 任务指令列表
export const PileUpdateCommandApi = (params) =>
  request.post("/admin/piles_software_update_task/get_command_list", params);
// 失败的指令重建任务
export const FailedCommandApi = (params) =>
  request.post(
    "/admin/piles_software_update_task/failed_command_recreate_task",
    params
  );
// 充电桩仓库 充电桩列表
export const PileStoreListApi = (params) =>
  request.post("/admin/piles_store/list", params);
// 充电桩列表排序信息
export const PileSortApi = () =>
  request.get("/admin/piles_store/sort_list_info");
// 工厂管理 工厂列表
export const FactoryListApi = (params) =>
  request.post("/admin/factory/list", params);
// 工厂列表排序信息
export const FactorySortApi = () =>
  request.get("/admin/factory/sort_list_info");
// 工厂管理 添加工厂
export const FactoryAddApi = (params) =>
  request.post("/admin/factory/add", params);